<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
      v-if="this.powerCheck('获取证书列表')"
    >
      <el-card class="box-card">
        <div class="certificate_ctrlCol">
          <div class="certificate_searchCol">
          </div>
          <div class="certificate_functionCol">
            <div class="certificate_searchCol_button">
              <el-button
                type="primary"
                size="small"
                @click="this.addVisible = true"
                v-if="this.powerCheck('添加证书')"
              >创建证书</el-button>
            </div>
          </div>
        </div>
        <div class="certificate_table">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="index"
              label=" "
              width="80"
            >
              <template #default="scope">
                <span style="margin-left: 10px">{{ ((this.pageNum - 1) * this.pageSize) + (scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="证书名称"
            />
            <el-table-column
              prop="status"
              label="证书状态"
            >
              <template #default="scope">
                <span v-if="scope.row.status == 1">启用</span>
                <span v-else>禁用</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="issueCount"
              label="颁发数量"
              sortable
            />
            <el-table-column
              prop="address"
              label="操作"
            >
              <template #default="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="this.startEdit(scope.row.id)"
                  v-if="this.powerCheck('编辑证书')"
                >编辑</el-button>
                <el-popconfirm
                  confirmButtonText="确定"
                  cancelButtonText="取消"
                  icon="el-icon-info"
                  iconColor="red"
                  title="确定删除吗？"
                  @confirm="this.delete(scope.row.id)"
                >
                  <template #reference>
                    <el-button
                      type="danger"
                      size="small"
                      v-if="this.powerCheck('删除证书')"
                    >删除</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="this.sizeChange"
            @current-change="this.pageChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <AddCertificate
      :isVisible="this.addVisible"
      @closeAct="this.closeAdd"
      @addSuccess="this.addSuccess"
    />
    <EditCertificate
      :isVisible="this.editVisible"
      :editCertificateID="this.editCertificateID"
      @closeAct="this.closeEdit"
      @editSuccess="this.editSuccess"
    />
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import AddCertificate from "./addCertificate";
import EditCertificate from "./editCertificate";
import { powerCheck, powerListCheck } from "@/tools/util";
import { ref } from "vue";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      total: 1,
      allPower: ["获取证书列表"],
      tableData: [],
      loading: false,
      searchKeyWordInput: ref(""),
      addVisible: false,
      editVisible: false,
      editCertificateID: 0,
    };
  },
  loading: false,
  components: {
    MainLayout,
    AddCertificate,
    EditCertificate,
  },
  created() {
    this.getData();
    // this.getQiniuToken();
  },
  methods: {
    powerCheck,
    powerListCheck,
    sizeChange(e) {
      this.pageSize = e;
      this.getData();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getData();
    },
    startEdit(idx) {
      this.editCertificateID = idx;
      this.editVisible = true;
    },
    closeEdit() {
      this.editVisible = false;
    },
    editSuccess(newData) {
      console.log(newData);
      let newTableData = [...this.tableData];
      for (let i in newTableData) {
        if (newData.id == newTableData[i].id) newTableData[i] = {
          ...newTableData[i],
          ...newData
        };
      }
      this.tableData = newTableData;
      this.editVisible = false;
    },
    delete(id) {
      ax("delCertificateList", { urlParam: { id } })
        .then(() => {
          let newTableData = [];
          for (let i in this.tableData) {
            if (id != this.tableData[i].id)
              newTableData.push(this.tableData[i]);
          }
          this.tableData = newTableData;
          ElMessage.success({
            message: "证书已删除",
            type: "success",
          });
        })
        .catch((e) => {
          ElMessage.error({
            message: e.msg,
            type: "error",
          });
        });
    },
    getData() {
      // console.log(e)
      this.loading = true;
      let queryObject = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      console.log(queryObject);
      if (this.searchText != "") {
        queryObject.searchText = this.searchText;
      }
      ax("getCertificateList", { data: queryObject })
        .then((res) => {
          console.log(res);
          if (!res.data.list) res.data.list = [];
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeAdd() {
      this.addVisible = false;
    },
    addSuccess(newData) {
      console.log(newData);
      this.addVisible = false;
      this.tableData = [...this.tableData, newData];
    },
  },
};
</script>

<style lang="less">
.certificate_ctrlCol {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.certificate_chooseSchool,
.certificate_searchCol {
  flex: 2;
}
.certificate_searchCol {
  display: flex;
  align-items: center;
}
.certificate_searchCol_input {
  width: 200px;
}
.certificate_searchCol_button {
  flex: 1;
  padding-left: 10px;
}
.certificate_functionCol {
  flex: 2;
  text-align: right;
}
.certificate_table {
}
</style>
