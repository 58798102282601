<template>
  <el-dialog
    title="编辑活动"
    :model-value="this.isVisible"
    width="80%"
    destroy-on-close
    @close="this.$emit('closeAct')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="assAccount_areaBody">
      <el-form
        ref="submitData"
        :model="submitData"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="submitData.name"></el-input>
        </el-form-item>
        <el-form-item label="活动简介" prop="describe">
          <el-input
            type="textarea"
            :rows="2"
            v-model="submitData.describe"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否热门" prop="isPopular">
          <el-radio-group v-model="submitData.isPopular" size="small">
            <el-radio-button label="true">是</el-radio-button>
            <el-radio-button label="false">否</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="活动封面" prop="coverURL">
          <el-upload
            action="https://up-z2.qiniup.com"
            list-type="text"
            :on-success="this.uploadSuccess_coverURL"
            :before-upload="getQNToken"
            :data="qiniuData"
            accept=".png,.jpg"
            :show-file-list="false"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div class="tipword">
            只能上传jpg或png文件，建议尺寸750*400,最多上传一张
          </div>
          <el-image
            v-if="this.submitData.coverURL != ''"
            style="width: 375px; height: 200px"
            :src="`${this.submitData.coverURL}`"
            :fit="fill"
          ></el-image>
        </el-form-item>
        <el-form-item label="活动顶图" prop="bannerURLList">
          <el-upload
            action="https://up-z2.qiniup.com"
            list-type="text"
            :on-success="this.uploadSuccess_bannerURLList"
            :before-upload="getQNToken"
            :data="qiniuData"
            accept=".png,.jpg"
            :show-file-list="false"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div class="tipword">
            只能上传jpg或png文件，建议尺寸750*400,最多上传一张
          </div>
          <el-image
            v-for="(item, index) in this.submitData.bannerURLList"
            style="width: 375px; height: 200px; margin-right: 18px"
            :src="item"
            :fit="fill"
            :key="index"
          ></el-image>
        </el-form-item>
        <el-form-item label="活动海报" prop="posterURL">
          <el-upload
            action="https://up-z2.qiniup.com"
            list-type="text"
            :on-success="this.uploadSuccess_posterURL"
            :before-upload="getQNToken"
            :data="qiniuData"
            accept=".png,.jpg"
            :show-file-list="false"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div class="tipword">
            只能上传jpg或png文件，建议尺寸750*400,最多上传一张
          </div>
          <el-image
            v-if="this.submitData.posterURL != ''"
            style="width: 375px; height: 200px"
            :src="`${this.submitData.posterURL}`"
            :fit="fill"
          ></el-image>
        </el-form-item>
        <el-form-item label="目标人数" prop="target">
          <el-input-number
            v-model="submitData.target"
            :controls="false"
            :min="0"
            :step="1"
            :precision="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="公益时长" prop="target">
          <el-input-number
            v-model="submitData.volunteerTime"
            :min="0"
            :step="0.5"
            :precision="1"
          ></el-input-number>
          <div class="tipword">（单位：小时，步长：0.5）</div>
        </el-form-item>

        <el-form-item label="活动地点" prop="address">
          <el-input v-model="submitData.address"></el-input>
        </el-form-item>
        <el-form-item label="参与范围" prop="address">
          <el-select-v2
            v-model="submitData.orgIDList"
            :options="this.allOrgs"
            placeholder="请选择"
            style="width: 100%"
            filterable
            multiple
            clearable
          >
            <template #default="{ item }">
              <span style="margin-right: 8px">{{ item.label }}</span>
              <span
                style="
                  color: var(--el-text-color-secondary);
                  font-size: 13px;
                  margin-right: 8px;
                "
              >
                {{ this.orgType[item.type] }}
                {{
                  item.type == "1"
                    ? `(${this.schoolType[item.schoolType]})`
                    : ""
                }}
              </span>
              <span
                style="color: var(--el-text-color-secondary); font-size: 13px"
              >
                {{ item.addressWord.join("/") }}
              </span>
            </template>
          </el-select-v2>
          <div class="tipword">（不选择表示所有人都可以参与活动）</div>
          <el-card class="box-card">
            <div>
              快速选择工具:
              <!-- <el-radio-group
                v-model="orgQuickChooseTool.type"
                size="small"
              >
                <el-radio-button label="do">选择</el-radio-button>
                <el-radio-button label="undo">反选</el-radio-button>
              </el-radio-group> -->
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 16px"
                @click="this.quickChoose()"
                >执行</el-button
              >
              <el-button type="info" size="mini" @click="this.reset()"
                >重置</el-button
              >
            </div>
            <br />
            按地址选择：
            <AddressChoose
              @updateData="this.dealOrgQuickChooseToolAddress"
              :propsData="orgQuickChooseTool.address"
            />
            <br />
            <br />
            按学校类型选择：
            <el-radio-group
              v-model="orgQuickChooseTool.schoolType"
              size="small"
            >
              <el-radio-button label="1">大学</el-radio-button>
              <el-radio-button label="2">高中</el-radio-button>
              <el-radio-button label="3">中职</el-radio-button>
              <el-radio-button label="4">初中</el-radio-button>
              <el-radio-button label="5">小学</el-radio-button>
            </el-radio-group>
          </el-card>
        </el-form-item>

        <el-form-item label="报名时间" prop="signTime">
          <el-date-picker
            v-model="submitData.signTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item label="活动时间" prop="time">
          <el-date-picker
            v-model="submitData.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>

        <el-form-item label="选择证书">
          <el-select-v2
            v-model="submitData.certificateID"
            :options="this.allCentList"
            placeholder="请选择"
            style="width: 100%"
            filterable
            clearable
          >
          </el-select-v2>
        </el-form-item>
        <el-form-item label="活动内容">
          <TinymceEditor
            :value="submitData.content"
            @updateValue="this.updateContent"
            ref="editor"
          ></TinymceEditor>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.$emit('closeAct')" :loading="this.submitLoading"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="this.submit"
          :loading="this.submitLoading"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
// import { getSinAllPowers } from "@/tools/util";
import AddressChoose from "@/components/addressChoose";
import TinymceEditor from "@/components/tinyMCE";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
import moment from "moment";

export default {
  props: {
    isVisible: Boolean,
    editData: String,
    closeAct: Function,
    editSuccess: Function,
  },
  data() {
    return {
      visible: false,
      powerList: [],
      submitLoading: false,
      allOrgs: [],
      allCentList: [],
      qiniuData: {
        token: "",
        // key: "",
      },
      orgQuickChooseTool: {
        type: "do",
        address: [],
        schoolType: 0,
      },
      editID: 0,
      submitData: {
        name: "", //活动名称
        describe: "", // 活动简介
        isPopular: false, // 是否热门
        volunteerTime: 0, //义工时长
        address: "", //活动地点
        coverURL: "",
        bannerURLList: [],
        posterURL: "",
        target: 0, //目标人数
        orgIDList: [], //参与范围
        time: [],
        signTime: [],
        startTime: 0,
        endTime: 0,
        signStartTime: 0,
        signEndTime: 0,
        certificateID: 0, //证书ID
        content: "", //活动内容
      },
      surekeyword: "",
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        sign_time: [
          { required: true, message: "请选择报名时间", trigger: "blur" },
        ],
        time: [{ required: true, message: "请选择活动时间", trigger: "blur" }],
        // schoolType: [
        //   { required: true, message: "请输入组织名称", trigger: "blur" },
        //   { validator: validateSchoolType, trigger: "blur" },
        // ],
      },
      schoolType: {
        1: "大学",
        2: "高中",
        3: "中职",
        4: "初中",
        5: "小学",
        0: "未设置",
      },
      orgType: { 1: "学校", 2: "社会", 0: "未设置" },
    };
  },
  components: {
    AddressChoose,
    TinymceEditor,
  },
  watch: {
    isVisible(newValue) {
      // console.log(newValue);
      this.visible = newValue;
      if (newValue) {
        // 每次打开时刷新
        this.getAllOrgs();
        this.getAllCents();
        this.getEditData();
      }
    },
    editData(newValue) {
      // console.log(newValue);
      this.editID = newValue;
      this.getEditData();
    },
  },

  methods: {
    getEditData() {
      if (this.editID == 0) return;
      ax("getActivity", { urlParam: { id: this.editID } })
        .then((res) => {
          console.log(res);
          // if(this.editID ) {}
          let newSubmitJSON = {
            name: "", //活动名称
            describe: "", // 活动简介
            isPopular: false, // 是否热门
            volunteerTime: 0, //义工时长
            address: "", //活动地点
            coverURL: "",
            bannerURLList: [],
            posterURL: "",
            target: 0, //目标人数
            orgIDList: [], //参与范围
            time: [],
            signTime: [],
            startTime: 0,
            endTime: 0,
            signStartTime: 0,
            signEndTime: 0,
            certificateID: 0, //证书ID
            content: "", //活动内容
          };
          for (let i in res.data) newSubmitJSON[i] = res.data[i];
          newSubmitJSON.signTime = [
            moment(newSubmitJSON.signStartTime * 1000),
            moment(newSubmitJSON.signEndTime * 1000),
          ];
          newSubmitJSON.time = [
            moment(newSubmitJSON.startTime * 1000),
            moment(newSubmitJSON.endTime * 1000),
          ];
          newSubmitJSON.volunteerTime = newSubmitJSON.volunteerTime / 60 / 60;
          console.log(newSubmitJSON);
          this.submitData = newSubmitJSON;
        })
        .catch((err) => {
          console.log(err);
          ElMessage.error("获取活动信息失败，请尝试刷新后重试。");
        });
    },
    dealAddress(addressJson) {
      //   console.log(addressJson);
      for (let i in addressJson.value) {
        // console.log(i);
        // console.log(addressJson.value[i]);
        switch (i) {
          case "0":
            // console.log(addressJson.label[i]);
            this.submitData.province = addressJson.label[i];
            this.submitData.provinceIndex = addressJson.value[i];
            break;
          case "1":
            this.submitData.city = addressJson.label[i];
            this.submitData.cityIndex = addressJson.value[i];
            break;
          case "2":
            this.submitData.county = addressJson.label[i];
            this.submitData.countyIndex = addressJson.value[i];
            break;
        }
      }
    },
    dealOrgQuickChooseToolAddress(addressJson) {
      console.log(addressJson.value);
      this.orgQuickChooseTool.address = addressJson.value;
    },
    getAllCents() {
      ax("getCertificateListSimple")
        .then((res) => {
          console.log(res);
          let thisCentList = [];
          let reqList = res.data.list;
          for (let i in reqList) {
            thisCentList.push({
              value: reqList[i].id,
              label: reqList[i].name,
            });
          }
          this.allCentList = thisCentList;
          //   setQNToken(res3.data);
          //   this.qiniuData.token = res3.data;
          //   return true;
        })
        .catch((error) => {
          console.log(error);
          sessionStorage.clear();
          ElMessage.error(
            "获取证书失败，您可能无法选择证书，请尝试刷新后重试。"
          );
          //   return false;
        });
    },
    getAllOrgs() {
      ax("getOrgListSimple")
        .then((res) => {
          console.log(res);
          let thisOrgList = [];
          let reqList = res.data.list;
          for (let i in reqList) {
            let addressCode = [];
            let addressWord = [];
            if (reqList[i].provinceIndex) {
              addressCode.push(reqList[i].provinceIndex);
              addressWord.push(reqList[i].province);
              if (reqList[i].cityIndex) {
                addressCode.push(reqList[i].cityIndex);
                addressWord.push(reqList[i].city);
                if (reqList[i].countyIndex) {
                  addressCode.push(reqList[i].countyIndex);
                  addressWord.push(reqList[i].county);
                }
              }
            }
            thisOrgList.push({
              value: reqList[i].id,
              label: reqList[i].name,
              addressCode,
              addressWord,
              type: reqList[i].type,
              schoolType: reqList[i].schoolType,
            });
          }
          this.allOrgs = thisOrgList;
          //   setQNToken(res3.data);
          //   this.qiniuData.token = res3.data;
          //   return true;
        })
        .catch((error) => {
          console.log(error);
          sessionStorage.clear();
          ElMessage.error(
            "获取组织列表失败，您可能无法选择参与范围，请尝试刷新后重试。"
          );
          //   return false;
        });
    },
    getQNToken() {
      return ax("getqiniutoken")
        .then((res3) => {
          console.log(res3);
          //   setQNToken(res3.data);
          this.qiniuData.token = res3.data;
          return true;
        })
        .catch((error) => {
          console.log(error);
          // this.loginNow = true;
          // sessionStorage.clear();
          ElMessage.error("上传失败。");
          return false;
        });
    },
    uploadSuccess_coverURL(res) {
      console.log(res);
      this.submitData.coverURL = "https://cdn.fryj.top/" + res.key;
    },
    uploadSuccess_bannerURLList(res) {
      console.log(res);

      let newList = [
        ...this.submitData.bannerURLList,
        "https://cdn.fryj.top/" + res.key,
      ];
      console.log(newList);
      this.submitData.bannerURLList = newList;
    },
    uploadSuccess_cover_url(res) {
      console.log(res);
      this.submitData.cover_url = "https://cdn.fryj.top/" + res.key;
    },
    updateContent(val) {
      // console.log(val);
      this.submitData.content = val;
    },
    uploadSuccess_banner_url_list(res) {
      console.log(res);

      let newList = [
        ...this.submitData.banner_url_list,
        "https://cdn.fryj.top/" + res.key,
      ];
      console.log(newList);
      this.submitData.banner_url_list = newList;
    },
    uploadSuccess_poster_url(res) {
      console.log(res);
      this.submitData.poster_url = "https://cdn.fryj.top/" + res.key;
    },
    // uploadSuccess:{
    //     cover_url(res){
    //         console.log(res);
    //     },
    //     banner_url_list(res){
    //         console.log(res);
    //     },
    //     org_id_list(res){
    //         console.log(res);
    //     },
    // },
    quickChoose() {
      // console.log(this.allOrgs[0].addressCode.join(','));
      console.log(this.orgQuickChooseTool.type);
      console.log(this.orgQuickChooseTool.address);
      console.log(this.orgQuickChooseTool.schoolType);
      let addressFilt = this.orgQuickChooseTool.address.length != 0;
      let schoolTypeFilt = this.orgQuickChooseTool.schoolType != 0;
      let dealID = [];
      // console.log(this.allOrgs);
      for (let i in this.allOrgs) {
        console.log(this.allOrgs[i].addressCode);
        let thisAddressCode = [];
        for (let j in this.orgQuickChooseTool.address) {
          thisAddressCode.push(this.allOrgs[i].addressCode[j]);
        }
        console.log(thisAddressCode);
        // if(!this.allOrgs[i].addressCode)  this.allOrgs[i].addressCode=[]
        let t1 = true;
        let t2 = true;
        if (addressFilt)
          t1 =
            this.orgQuickChooseTool.address.join(",") ==
            thisAddressCode.join(",");
        if (schoolTypeFilt)
          t2 = this.allOrgs[i].schoolType == this.orgQuickChooseTool.schoolType;
        // console.log(t1, t2);
        if (t1 && t2) {
          // console.log({ ...this.allOrgs[i] });
          dealID.push(this.allOrgs[i].value);
        }
      }
      console.log(dealID);
      let newOrgIDList = [...this.submitData.orgIDList];
      for (let i in dealID) {
        if (newOrgIDList.indexOf(dealID[i]) == -1) newOrgIDList.push(dealID[i]);
      }
      console.log(newOrgIDList);
      this.submitData.orgIDList = newOrgIDList;
    },
    reset() {
      this.orgQuickChooseTool.address = [];
      this.orgQuickChooseTool.schoolType = 0;
    },
    submit() {
      this.$refs["submitData"].validate((valid) => {
        if (valid) {
          //   this.submitLoading = true;
          let submitJSON = this.submitData;
          let toInt = ["volunteerTime", "target", "certificateID"];
          for (let i in toInt) {
            if (submitJSON[toInt[i]] == "") {
              submitJSON[toInt[i]] = 0;
            } else if (typeof submitJSON[toInt[i]] == "string") {
              submitJSON[toInt[i]] = parseInt(submitJSON[toInt[i]]);
            }
          }
          submitJSON.startTime = moment(submitJSON.time[0]).valueOf() / 1000;
          submitJSON.endTime = moment(submitJSON.time[1]).valueOf() / 1000;
          submitJSON.signStartTime =
            moment(submitJSON.signTime[0]).valueOf() / 1000;
          submitJSON.signEndTime =
            moment(submitJSON.signTime[1]).valueOf() / 1000;
          // console.log({ ...submitJSON });
          submitJSON.isPopular = submitJSON.isPopular == "true";
          submitJSON.volunteerTime = submitJSON.volunteerTime * 60 * 60;
          delete submitJSON.time;
          delete submitJSON.signTime;
          let addSubmitJSON = { ...submitJSON };
          console.log({ ...submitJSON });

          ax("editActivity", {
            data: submitJSON,
            urlParam: { id: this.editID },
          })
            .then((res) => {
              console.log(res);
              this.submitLoading = false;
              // this.$refs["submitData"].resetFields();
              this.$emit("editSuccess", { id: this.editID, ...addSubmitJSON });
            })
            .catch((err) => {
              console.log(err);
              this.submitLoading = false;
              ElMessage.error(err.msg);
            });
          //   console.log(submitJSON)
          // 获取权限
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.assAccount_areaBody {
  max-height: 50vh;
  padding: 0 16px;
  overflow: auto;
}
.tipword {
  background-color: #fff;
  font-size: 12px;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
</style>